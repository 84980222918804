import React, {Component} from 'react';
import styles from './ReportWord.module.css';
import Shake from 'react-reveal/Shake';
import {analyticsEvent} from "../../services/service-analytics"
import Fade from 'react-reveal/Fade'; 

class ReportWord extends Component {
  constructor(props) {
    super(props)

    this.onReportWord = this.onReportWord.bind(this);
    this.setReportWordProgress = this.setReportWordProgress.bind(this);
    this.resetReportWord = this.resetReportWord.bind(this);
    this.copySearchText = this.copySearchText.bind(this);
    this.onInputChange = this.onInputChange.bind(this);


    this.state = {
      reportWordProgress: 0,
      reportedWord: null,
      noReportedWordEntered: 0
    }
  }

  onReportWord() {

    if(this.state.reportedWord)
    {
      analyticsEvent("Reported Word", this.state.reportedWord);

      this.setState({
        reportWordProgress: 2,
        noReportedWordEntered: 0
      })
    }
    else{
      this.setState({ noReportedWordEntered: this.state.noReportedWordEntered + 1 });
    }


  }


  setReportWordProgress(number) {

    this.setState({
      reportWordProgress: number
    }, () =>{

      if(this.state.reportWordProgress === 1){
        this.copySearchText();

      }
    })
  
  }

  resetReportWord() {

    var searchInput = document.querySelector("#search-input");
    if(searchInput){
      searchInput.value = "";
    } 
 
    this.setState({
      reportWordProgress: 0,
      reportedWord: null
    }, () =>{

      this.props.setSearching(false, "");
    })

  }

  copySearchText(){
    var searchInput = document.querySelector("#search-input");
    if(searchInput){
      var reportInput = document.querySelector("#report-input");
      if(reportInput){
        var searchValue = searchInput.value
        reportInput.value = searchValue;

        this.setState({
          reportedWord: searchValue.toLowerCase()
        })
      }
    }
  }

  onInputChange(event) {
    const val = event.target.value.toLowerCase();

    this.setState({
      reportedWord: val
    })
  }

  render() {
    const {reportWordProgress, reportedWord} = this.state

    var inlineStyle = {
     maxHeight: reportWordProgress === 2 ? '0px' : '3000px', 
      opacity: reportWordProgress === 2 ? '0' : '1'
    };

    var inlineStyleSuccess = {
      opacity: reportWordProgress === 2 ? '1' : '0',
      zIndex: reportWordProgress === 2 ? '1' : '-1',
      top: reportWordProgress === 2 ? '0' : '10px'
    };

  

    const {
      reportwordHeadline, 
    reportwordDescription,
    reportwordHeadlineSuccess,
    reportwordDescriptionSuccess
    } = this.props.texts

    return (
      <section className={styles.ReportWord}>
          <div className="container">
            <div className="row">
              <div className={styles.ReportWordStart}>
                <button className={styles.ButtonStart + " button-primary"} onClick={() => this.setReportWordProgress(1)}>Foreslå ord</button>
              </div>
          
              {reportWordProgress > 0 &&
                <div className={styles.ReportWordInfo}>
                  <div className="container">
                    <div className="row">
                        <div className={styles.ReportWordInfoHelp} style={inlineStyle}>
                          <div className="columns col-xs-12 col-md-9">
                            <div className={styles.Breadcrumb + " no-select"} onClick={() => this.setReportWordProgress(0)}>❮</div>
                            <Fade bottom distance={"40px"} delay={150}>
                            <div className={styles.FadeIn + " h1"}>{reportwordHeadline}</div>
                            </Fade>
                          </div>
                          <div className="columns col-xs-12 col-md-7 col-md-offset-5">
                            <div className={styles.FadeIn + " richtext"}>
                              <p>{reportwordDescription}</p>
                            </div>
                          </div>

                          <div className={styles.FadeIn + " columns col-xs-12"}>
                            <Shake spy={this.state.noReportedWordEntered}>
                              <input
                                    id="report-input"
                                    type="text"
                                    placeholder="Indtast ord"
                                    className={styles.input}
                                    onChange={this.onInputChange}
                                    autoComplete="off"
                                  
                                  />
                            </Shake>
                            <div className="md-text-center">
                              <button className={styles.ButtonReport + " button-primary"} onClick={() => this.onReportWord()}>Foreslå ord</button>
                            </div>
                          </div>
                        </div>
                    
                        <div className={styles.ReportWordSubmitted} style={inlineStyleSuccess}>
                            <div className="container">
                              <div className="row">
                            <div className="columns col-xs-12 col-md-9">
                              <div className="huge-headline h1">{reportwordHeadlineSuccess}</div>
                            </div>
                            <div className="columns col-xs-12 col-md-7 col-md-offset-5">
                              <p><b>"{reportedWord}"</b> {reportwordDescriptionSuccess}</p>
                              <button className={styles.ButtonReport + " button-primary"} onClick={() => this.resetReportWord()}>Tilbage til søg</button>
                            </div>
                          </div>
                          </div>
                        </div>
                        
                    </div>
              </div>
              </div>
              }
            </div>
          </div>
      </section>
    )
  }
}

export default ReportWord;
