import React, {Component} from 'react';
import styles from './CategorySwitcher.module.css';

class CategorySwitcher extends Component {
  constructor(props) {
    super(props)
    this.onClickFinance = this.onClickFinance.bind(this)
  }

  onClickFinance(bool) {
    this.props.globals.setFinance(bool)

    if(bool) {
      document.body.classList.add('animate-finansk');
      document.body.classList.remove('animate-digital'); 

      setTimeout(() =>{
        document.body.classList.remove('animate-finansk');
      }, 2000);
    } 
    else {
      document.body.classList.add('animate-digital');
       document.body.classList.remove('animate-finansk'); 

       setTimeout(() =>{
        document.body.classList.remove('animate-digital');
      }, 2000);
    }
  }

  render() {
    const {searching} = this.props

    return (
      <section className={searching ? styles.CategorySwitcherHide : styles.CategorySwitcher}>
        <div className={styles.labels}>
            <div className={styles.title} onClick={() => this.onClickFinance(false)}><span>Digital Dansk</span></div>
            <div className={styles.title + " " + styles.finans} onClick={() => this.onClickFinance(true)}><span>Finans Dansk</span></div>
        </div>
      </section>
    )
  }
}

export default CategorySwitcher;
