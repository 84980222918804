import React, {Component} from 'react';
import styles from './Search.module.css';
import sursmiley from "../../assets/img/sursmiley.svg"
import {Link} from "@reach/router"
import Fade from 'react-reveal/Fade'; 
import {analyticsEvent} from "../../services/service-analytics"

class Search extends Component {
  constructor(props) {
    super(props)

    this.onInputChange = this.onInputChange.bind(this)

    this.state = {
      filteredResults: []
    }
  }

  onInputChange(event) {
    const val = event.target.value
    const str = val.toLowerCase()

    const {
      words,
      setSearching
    } = this.props

    setSearching(val.length > 0, str);


    const results = str.length === 0 ? [] : words
      .filter(w => w.node.frontmatter.title.toLowerCase().includes(str))
      .map(w => w.node)

    this.setState({
      search: event.target.value,
      filteredResults: results
    }, () => {

      // Track words not found in searchbar
      if(results.length === 0)
      {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
          if(str !== ""){
            analyticsEvent("Search", "Word not found", str);
          }
          clearTimeout(this.timeout)
          this.timeout = null

        }, 2000)
      }
    })
  }



  render() {

    const {filteredResults} = this.state
    const {searchWord, searching} = this.props

    

    return (
      <>
        <section className={styles.Search}>
        <div className="container">
          <div className="row">
            <div className="columns col-xs-12">
              <input
                autoComplete="off"
                id="search-input"
                type="search"
                placeholder="Søg efter ord"
                value={searchWord}
                onChange={this.onInputChange}
                className={searching ? " searching" : ""}

              />
              <div className={styles.Results}>
                <Fade bottom cascade>
                  <ul className={styles.list}>
                    {filteredResults && filteredResults.length > 0 &&
                    filteredResults.map((r, idx) => (
                      <li key={idx} className={styles.listItem}>
                        <Link className="link" to={r.fields.slug}>{r.frontmatter.title}</Link>
                      </li>
                    ))
                    }
                  </ul>
                </Fade>
              </div>  
              <div className={styles.SeeAllWords} >
                <Link className={searching ? styles.Searching : ""} to="/ordliste">❯❯ Se alle ord ❮❮</Link>
              </div>

               {searching && filteredResults && filteredResults.length === 0 &&
                <div className={styles.NoResults}>
                  <div className="row">
                    <div className="columns col-xs-12 col-sm-5">
                 
                      <img src={sursmiley} className={styles.Smiley} alt="Ingen resultater" />
                    </div>
                    <div className="columns col-xs-12 col-sm-7">
                        <div className="richtext">
                          <p>
                          Øv.. Vi kunne desværre ikke finde det, du søgte efter. Hjælp os med at udvide ordbogen og foreslå dét ord, du ikke kunne finde, så sørger vi for, det bliver tilføjet.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
        </section>
   

      </>
    )
  }
}

export default Search;
