import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {StateConsumer} from '../components/StateContext'
import CategorySwitcher from "../components/CategorySwitcher/CategorySwitcher"
import Search from "../components/Search/Search"
import MostSearchedWords from "../components/MostSearchedWords/MostSearchedWords"
import ReportWord from "../components/ReportWord/ReportWord"
import {getCookieAcceptance} from "../services/service-cookies"
import MetaTags from "../components/MetaTags"
import Fade from 'react-reveal/Fade'; 
import Logo from "../components/Logo/Logo"

export class SearchPageTemplate extends Component {
  constructor(props) {
    super(props)

    this.setSearching = this.setSearching.bind(this)

    this.state = {
      searching: false,
      searchWord: ''
    }
  }

  setSearching(bool, word) {
    this.setState({
      searching: bool,
      searchWord: word
    })
  }

  componentDidMount(){

    if(getCookieAcceptance('finance')){
      if(typeof document !== "undefined"){
        document.body.classList.add('theme-finansk')
      }
    }
  }

  render() {
    const {data} = this.props
    const {searching, searchWord} = this.state

    console.log("data", data);

    if(!data) return null

    const {
      words
    } = data

    return (
      <StateConsumer>{stateContext =>
        <>
          <MetaTags title="Søg"/>
          
          <CategorySwitcher
            globals={stateContext}
            searching={searching}
          />

          {words.edges && 
          <>
          <Fade distance={"30px"}>
              <Search
              globals={stateContext}
              words={words.edges}
              searching={searching}
              searchWord={searchWord}
              setSearching={this.setSearching}
              showSeeAllWords={true}
            />
          </Fade>

      
            <MostSearchedWords 
            words={words.edges} 
            searching={searching} 
            globals={stateContext} />
          </>

          }

          <ReportWord texts={data.page.frontmatter}   setSearching={this.setSearching}/>
      
          <Logo searching={searching}   />    

        </>
      }
      </StateConsumer>
    )
  }
}

SearchPageTemplate.propTypes = {
  data: PropTypes.object
}

const SearchPage = (props) => {
  const {data} = props
  console.log('props', props)

  return (
    <SearchPageTemplate data={data}/>
  )
}

SearchPage.propTypes = {
  data: PropTypes.object
}

export default SearchPage

export const pageQuery = graphql`
  query SearchPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        templateKey
        reportwordHeadline
        reportwordDescription
        reportwordHeadlineSuccess
        reportwordDescriptionSuccess
      }
    }
    words: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title,] },
      filter: { frontmatter: { templateKey: { eq: "word-page" } }}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            finance
          }
        }
      }
    }
  }
`
