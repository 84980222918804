import React, {Component} from 'react';
import {Link} from '@reach/router'
import styles from './MostSearchedWords.module.css';
import { StaticQuery, graphql } from "gatsby"
import Fade from 'react-reveal/Fade'; 


class MostSearchedWords extends Component {


  render() {

    const {words, searching} = this.props
 

    return (
      <StaticQuery
        query={graphql`
					query WordQuery {
						allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "settings-page" } } }) {
							edges {
								node {
									frontmatter {
                    topSearchedWords{
                      title
                    }
									}
								}
							}
						}
					}
				`}
        render={(data) => {

          const { topSearchedWords } = data.allMarkdownRemark.edges[0].node.frontmatter

          if(topSearchedWords){
            topSearchedWords.forEach(function(topWord)
            {
              words.forEach(function(word){
                if(topWord.title === word.node.frontmatter.title)
                {
                  topWord.url = word.node.fields.slug;

                  const finance = word.node.frontmatter.finance;

                  if(!finance){
                    topWord.finance = false;
                  }
                  else{
                    topWord.finance = true;
                  }
                }
              });
            });
          }

          const {finance } = this.props.globals;

          var isFinance = false;

          if(!finance){
            isFinance = false;
          }
          else{
            isFinance = true;
          }


          return (
            <section className={searching ? styles.MostSearchedWordsHide : styles.MostSearchedWords}>
              <div className="container">
                <div className="row">
                  <div className="columns col-xs-12">
                    <h2>Mest søgte ord</h2>
                    <div className={styles.MostSearchedWordsWrapper}>

                      <Fade appear bottom cascade spy={isFinance} duration={800}>
                        <ul>                
                          {topSearchedWords && topSearchedWords.length > 0 &&
                            topSearchedWords.filter(w => w.finance === finance).map((r, idx) => (     
                            <li key={idx}>
                            <Link className="link" to={r.url}>{r.title}</Link>
                            </li>                      
                            ))                     
                          }
                  
                          {topSearchedWords.length === 0 &&
                            <li>Ingen</li>
                          }

                        </ul>
                      </Fade>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        }}
      />
    )
  }
}

export default MostSearchedWords;
